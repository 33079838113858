<template>
  <div>
    <v-card :height="height" :width="width">
      <div ref="googleMap" :style="{ width, height }"></div>
      <v-overlay
        absolute
        :value="loadingBusinessData || loadingMap"
        class="text-center"
      >
        <p class="mb-2">Cargando mapa...</p>
        <v-progress-circular indeterminate color="orange"></v-progress-circular>
      </v-overlay>
    </v-card>
    <v-row class="mt-2" v-if="businessData">
      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="4"
        xl="4"
        class="py-1"
        v-for="branch in businessData.branchOffices"
        :key="branch.id"
      >
        <v-btn small block @click="clicDynamicBranchOffice(branch.id)">{{
          branch.branch_office_name
        }}</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import { app } from "@/config/globals";
export default {
  data() {
    return {
      map: null,
      loadingMap: true,
      mapOptions: {
        mapTypeControl: false,
        streetViewControl: false,
        zoom: 12
      },
      markers: [],
      activeInfoWindow: null
    };
  },
  mounted() {
    this.initMapLoader();
  },
  methods: {
    async initMapLoader() {
      // :: init Loader
      const loader = new Loader({
        apiKey: app.mapsApiKey,
        version: "weekly",
        libraries: ["places"]
      });
      // :: Map container
      const mapContainer = this.$refs.googleMap;
      // eslint-disable-next-line
      if (typeof google === "object" && typeof google.maps === "object") {
        // eslint-disable-next-line
        this.map = new google.maps.Map(mapContainer, {
          ...this.mapOptions
        });
        if (this.businessData) {
          if (this.businessData.branchOffices) this.initBusinessMarkers();
        }
      } else {
        await loader.load();
        // eslint-disable-next-line
        this.map = new google.maps.Map(mapContainer, {
          ...this.mapOptions
        });
        if (this.businessData) {
          if (this.businessData.branchOffices) this.initBusinessMarkers();
        }
      }
    },
    initBusinessMarkers() {
      this.map.setCenter(this.businessData.location.map_point);
      if (this.businessData.branchOffices) {
        this.businessData.branchOffices.forEach(branchOffice => {
          const contentString =
            '<p class="text-center caption ma-0">Sucursal</p>' +
            '<div class="text-center text-capitalize text-subtitle-1 font-weight-medium pa-0">' +
            branchOffice.branch_office_name +
            "</div>";
          // eslint-disable-next-line
          const infowindow = new google.maps.InfoWindow({
            content: contentString
          });
          // eslint-disable-next-line
          const marker = new google.maps.Marker({
            position: branchOffice.location.map_point,
            map: this.map, // eslint-disable-next-line
            animation: google.maps.Animation.DROP,
            icon:
              "https://firebasestorage.googleapis.com/v0/b/smart-conecta.appspot.com/o/map_ssencial.png?alt=media&token=9eab7445-c732-4d9a-959e-ac299289dec2",
            title: branchOffice.branch_office_name
          });
          this.markers.push({ marker, id: branchOffice.id });
          this.loadingMap = false;
          marker.addListener("click", () => {
            if (this.activeInfoWindow) this.activeInfoWindow.close();
            infowindow.open(this.map, marker);
            this.activeInfoWindow = infowindow;
          });
        });
      }
    },
    clicDynamicBranchOffice(id) {
      const currentMarker = this.markers.find(marker => marker.id === id);
      if (this.activeInfoWindow) this.activeInfoWindow.close();
      // eslint-disable-next-line
      google.maps.event.trigger(currentMarker.marker, "click");
    }
  },
  watch: {
    businessData(newValue) {
      if (newValue) this.initBusinessMarkers();
    }
  },
  props: {
    height: {
      type: String,
      default: "300px"
    },
    width: {
      type: String,
      default: "100%"
    },
    loadingBusinessData: {
      type: Boolean
    },
    businessData: {
      type: Object,
      default: null
    }
  }
};
</script>
