<template>
  <div>
    <v-container class="background">
      <v-row>
        <v-col cols="12" class="my-3 text-center" v-if="loadingInfoBusiness">
          <p class="mb-0 caption">
            Cargando datos del negocio...
          </p>
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-col>
        <v-col class="text-center" cols="12" v-if="notFoundBuisness">
          <h3 class="text-h4 mt-2">404</h3>
          <h3 class="text-h5 mb-2">Tienda no encontrado</h3>
          <p class="subheadline ma-0">
            La Tienda que estás buscando no existe o a cambiado de dirección por
            favor corrobora el nombre de la tienda e intenta nuevamente.
          </p>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="4" xl="4">
          <v-expand-transition>
            <div
              v-if="businessData"
              class="mt-3"
              :class="{ 'my-7': $vuetify.breakpoint.lgAndUp }"
            >
              <div class="text-center mb-2">
                <v-avatar size="70" v-if="businessData.thumbnail">
                  <img
                    :src="businessData.thumbnail"
                    alt="business profile picture"
                  />
                </v-avatar>
                <h4 class="text-h4 text-uppercase" v-if="businessData">
                  {{ businessData.name }}
                </h4>
              </div>

              <div class="text-subtitle-1 font-weight-light text-capitalize">
                <v-icon size="20">category</v-icon
                ><span class="ml-1 font-weight-medium">Categoria:</span>
                {{ getCategoryName(businessData.categorie) }}
              </div>
              <div class="text-subtitle-1 font-weight-light text-capitalize">
                <v-icon size="18">location_on</v-icon
                ><span class="ml-1 font-weight-medium">Dirección:</span>
                {{ businessData.location | locationFormat }}
              </div>
              <div
                class="text-subtitle-1 font-weight-light mt-1"
                :class="{ 'text-center': !$vuetify.breakpoint.lgAndUp }"
              >
                <v-icon size="23" class="mr-1">date_range</v-icon
                ><span class="font-weight-medium">Días laborables</span>
                <v-item-group class="mt-1">
                  <v-item v-for="day in days" :key="day.value + '-day'">
                    <v-chip
                      :color="
                        businessData.business_hours_data.days.includes(
                          day.value
                        )
                          ? customerColor
                          : ''
                      "
                      :text-color="
                        businessData.business_hours_data.days.includes(
                          day.value
                        )
                          ? customerTextColorDays
                          : 'black'
                      "
                      class="mr-1"
                    >
                      {{ day.letter }}
                    </v-chip>
                  </v-item>
                </v-item-group>
                <div class="mt-3">
                  <v-icon size="23" class="mr-1">access_time</v-icon
                  ><span class="font-weight-medium">Horario laboral</span>
                  <p class="ma-0">
                    {{ businessData.business_hours_data | timeFormat }}
                  </p>
                </div>
              </div>
              <div class="my-3">
                <v-list nav dense class="pa-0">
                  <v-list-item-group
                    :color="
                      getBusinessDesign
                        ? getBusinessDesign.primary_color
                        : 'primary'
                    "
                    class="background"
                  >
                    <v-list-item
                      :to="{ name: item.name, params: item.params }"
                      class="grey lighten-4 elevation-2"
                      v-for="(item, i) in businessSections"
                      :key="i"
                      @click="hideMenu = false"
                    >
                      <v-list-item-icon class="mr-2 ml-0">
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </div>
          </v-expand-transition>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="8"
          xl="8"
          :class="{ 'pt-0': !$vuetify.breakpoint.lgAndUp }"
        >
          <p v-if="businessData" class="mb-3 text-h6 text-center">
            <v-icon size="28" class="mr-1">store</v-icon>Sucursales
          </p>
          <SsencialMapsBranchs
            class="mb-3"
            :loadingBusinessData="loadingInfoBusiness"
            :businessData="businessData"
            :notFoundBuisness="notFoundBuisness"
          />
        </v-col>
      </v-row>
    </v-container>
    <SsencialBusinessFooter
      :businessData="businessData"
      :loadingBusinessData="loadingInfoBusiness"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import BusinessMapsBranchs from "../components/maps/BusinessMapsBranchs";
import BusinessFooter from "../components/marketplace/Footer";
import moment from "moment";

export default {
  data() {
    return {
      loadingPage: true,
      loadingInfoBusiness: true,
      notFoundBuisness: false,
      businessSections: [
        {
          text: "Productos",
          icon: "shopping_basket",
          params: { name: this.businessName },
          name: "businessMarketHome"
        },
        {
          text: "Ordenes",
          icon: "archive",
          name: "businessOrders",
          params: { name: this.businessName }
        }
      ]
    };
  },
  components: {
    SsencialMapsBranchs: BusinessMapsBranchs,
    SsencialBusinessFooter: BusinessFooter
  },
  created() {
    this.checkIstSameBusiness();
  },
  computed: {
    ...mapState("business", ["businessData"]),
    ...mapState("app", ["days", "categories"]),
    ...mapGetters("business", ["getBusinessDesign"]),
    customerTextColor() {
      if (this.getBusinessDesign)
        if (this.getBusinessDesign.header_color_text === "white") return true;
        else return false;
      else return true;
    },
    customerColor() {
      if (this.getBusinessDesign) return this.getBusinessDesign.primary_color;
      else return "primary";
    },
    customerTextColorDays() {
      if (this.getBusinessDesign)
        return this.getBusinessDesign.header_color_text;
      else return "white";
    }
  },
  methods: {
    ...mapActions("app", ["initBuinessDatabase", "checkCurrentBusiness"]),
    async getBuinessDatabase(businessName) {
      const resultBusiness = await this.initBuinessDatabase(businessName);
      switch (resultBusiness) {
        case "NOT_FOUND":
          this.loadingInfoBusiness = false;
          this.notFoundBuisness = true;
          break;
        default:
          this.loadingInfoBusiness = false;
          break;
      }
    },
    async checkIstSameBusiness() {
      const currentBusiness = await this.checkCurrentBusiness(
        this.businessName
      );
      switch (currentBusiness) {
        case "NOT_FOUND":
          this.loadingInfoBusiness = false;
          this.notFoundBuisness = true;
          break;
        case "NOT_SAME":
          this.getBuinessDatabase(this.businessName);
          break;
        case "OK":
          this.loadingInfoBusiness = false;
          break;
      }
    },
    getCategoryName(categorie) {
      const cate = this.categories.find(x => x.value === categorie).categorie;
      if (cate) return cate;
      return "";
    }
  },
  props: {
    businessName: {
      required: true,
      type: String
    }
  },
  filters: {
    locationFormat(location) {
      return `${location.street_name}, ${location.city_name}, ${location.state_name}, México, C.P. ${location.postal_code}`;
    },
    timeFormat(timeObject) {
      return `${moment(timeObject.open, ["HH"]).format(
        "hh A"
      )} - ${moment(timeObject.close, ["HH"]).format("hh A")}`;
    }
  }
};
</script>
